import axios from 'axios'

export default ({ url, config }) => {
  const endpoint = '/user/auth'
  return {
    login: data => axios.post(`${url}${endpoint}/login`, data, config()),
    forgot: data => axios.post(`${url}${endpoint}/forgot`, data, config()),
    // logout: () => axios.get(`${url}${endpoint}/logout`, config()),
  }
}

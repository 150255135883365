let apiUrl = process.env.REACT_APP_API_URL
let apiVersion = process.env.REACT_APP_API_VERSION
let siteUrl = ''

if(process.env.NODE_ENV == 'production'){
  apiUrl = process.env.REACT_APP_API_URL_PROD
  siteUrl = process.env.REACT_APP_SITE_URL_PROD
}

export const SITE_URL = siteUrl
export const API_VERSION = apiVersion
export const API_URL = `${apiUrl}/${apiVersion}`
export const API_KEY = process.env.REACT_APP_API_KEY
export const API_KEY_PROD = process.env.REACT_APP_API_KEY_PROD

export const SITE_COOKIES = { 
  TOKEN: '_BNIELearningAppToken',
  EMAIL: '_BNIELearningAppEmail',
  NAME: '_BNIELearningAppName',
  OUTLET: '_BNIELearningAppOutlet',
  POSITION: '_BNIELearningAppPosition',
  AVATAR: '_BNIELearningAppAvatar',
  NPP: '_BNIELearningAppNPP',
  BRANCH: '_BNIELearningAppBranch',
  REGION: '_BNIELearningAppRegion',
}

export const MENU = {
  LOGIN: `${SITE_URL}/login`,
  FORGOT_PASSWORD: `${SITE_URL}/forgot-password`,
  APP: `${SITE_URL}/app`,
  HOME: `${SITE_URL}/app/home`,
  QUIZ: `${SITE_URL}/app/quiz`,
  PROFILE: `${SITE_URL}/app/profile`,
  RESPONSE_SUCCESS: `${SITE_URL}/app/response-success`,
}

export const ASSETS = {
  LOGO: {
    brand: `${SITE_URL}/images/logo-BNI-46-1.png`,
  },
  ICONS: {
    menu: `${SITE_URL}/images/icon-menu.png`,
    book: `${SITE_URL}/images/icon-book.png`,
    taq: `${SITE_URL}/images/icon-taq.png`,
    clock: `${SITE_URL}/images/icon-clock.png`,
    menu_color: `${SITE_URL}/images/icon-menu-color.png`,
    close: `${SITE_URL}/images/close.png`,
    
    settings: `${SITE_URL}/images/settings.png`,
    star: `${SITE_URL}/images/star.png`,
    success: `${SITE_URL}/images/success.png`,
    star_disabled: `${SITE_URL}/images/star-disabled.png`,
    left_arrow: `${SITE_URL}/images/left-arrow.png`,
    magnifying_glass: `${SITE_URL}/images/magnifying-glass.png`,
    magnifying_glass_dark: `${SITE_URL}/images/magnifying-glass-dark.png`,
  }
}
import React from "react";
import { withRouter } from "react-router-dom";
import { UserApi, BACK_TO_LOGIN } from "../../api";
import { Toast } from "antd-mobile";
import { ASSETS, SITE_COOKIES } from "../../config";
import { setCookie } from "../../utils/cookies";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      source: {},
      data: {},
      form: {
        password: "",
        confirm_password: "",
      },
      file: "",
      pristine: true,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.fetchProfile();
  };

  fetchProfile = () => {
    UserApi.profile()
      .then(response => {
        if (response.data.status == 200) {
          let { data } = response.data;
          this.setState({
            data,
            source: {
              fullname: data.fullname,
              email: data.email,
            },
            form: {
              password: "",
              confirm_password: "",
            },
          });
        }
      })
      .catch(err => {
        Toast.fail("Terjadi kesalahan memuat data profile", 1.5, null, true);
      });
  };

  handleChangeInput = (e, pname, fname) => {
    let { form, data, pristine } = this.state;
    if (pname == "form") form[fname] = e.target.value;
    else data[fname] = e.target.value;
    this.setState({ form, data, pristine: false });
  };

  handleChangeImage = async (e, file) => {
    if (file.length == 1) {
      if (file[0]["type"] == "image/jpeg" || file[0]["type"] == "image/png") {
        await this.setState({ file: file[0], pristine: false });
        this.handleSubmit(e, true);
      } else {
        Toast.fail("Format gambar tidak sesuai", 1.5, null, true);
      }
    }
  };

  handleSubmit = async (e, isImage = false) => {
    e.preventDefault();
    let {
      source,
      form: { password, confirm_password },
      data: { fullname, email, image },
      file,
      pristine,
      isLoading,
    } = this.state;
    if (!pristine) {
      Toast.loading("Loading...", 1000, null, true);
      if (!isLoading) {
        await this.setState({ isLoading: true });
        let isMatchPassword = false;
        let formData = new FormData();
        if (password != "") {
          if (confirm_password == "") {
            // alert isi confirm password
            isMatchPassword = false;
            Toast.fail("Mohon konfirmasi password", 1.5, null, true);
            this.setState({ isLoading: false });
          } else {
            if (confirm_password == password) {
              isMatchPassword = true;
              formData.append("password", password);
            } else {
              // alert password tidak sesuai
              isMatchPassword = false;
              Toast.fail("Password tidak sesuai", 1.5, null, true);
              this.setState({ isLoading: false });
            }
          }
        } else {
          isMatchPassword = true;
        }

        if (isMatchPassword) {
          if (source.fullname != fullname || password != "" || file != "")
            formData.append("name", fullname);
          if (source.email != email) formData.append("email", email);
          if (file != "") formData.append("image", file);
          var useApi = isImage
            ? UserApi.updateProfileImage(formData)
            : UserApi.updateProfile(formData);

          useApi
            .then(async response => {
              if (response.data.status == 200) {
                const { data, message } = response.data;
                Toast.success(message, 3, null, true);
                await setCookie(SITE_COOKIES.NAME, fullname, 1);
                if (isImage) {
                  await setCookie(SITE_COOKIES.AVATAR, data.image, 1);
                }
                await this.setState({ isLoading: false, pristine: true });
                await this.fetchProfile();
              }
            })
            .catch(e => {
              Toast.hide();
              Toast.fail("Terjadi kesalahan update data", 1.5, null, true);
              this.setState({ isLoading: false });
            });
        }
      }
    } else {
      Toast.info("Tidak ada profile yang diubah", 2.5, null, true);
    }
  };

  handleBack = e => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleLogout = () => {
    BACK_TO_LOGIN(true);
  };

  render() {
    let {
      form: { password, confirm_password },
      data: {
        fullname,
        email,
        region_name,
        branch_name,
        outlet_name,
        position_name,
        image,
      },
      isLoading,
    } = this.state;

    return (
      <div className="profile-wrapper">
        <form onSubmit={this.handleSubmit}>
          <div className="profile-header">
            <a
              href=""
              onClick={e => this.handleBack(e)}
              className="icon-back"
              style={{
                position: "absolute",
                left: 25,
                top: 25,
                filter: "invert(100%)",
              }}>
              <img
                src={ASSETS.ICONS.left_arrow}
                style={{ height: 25, width: 25 }}
              />
            </a>
            <div className="title">My Profile</div>
            <div
              className="photo"
              style={{ backgroundImage: `url('${image}')` }}>
              <label
                className="btn-upload"
                htmlFor="inputFile"
                style={{
                  backgroundColor: "transparent",
                  height: 120,
                  width: 120,
                }}></label>
              <input
                className="field-upload"
                type="file"
                id="inputFile"
                onChange={e => this.handleChangeImage(e, e.target.files)}
              />
            </div>
          </div>
          <div className="profile-body">
            <div className="profile-row">
              <span className="label">Name</span>
              <input
                type="text"
                className="value"
                value={fullname}
                onChange={e => this.handleChangeInput(e, "data", "fullname")}
              />
            </div>
            <div className="profile-row">
              <span className="label">Email Address</span>
              <input
                type="email"
                className="value"
                value={email}
                onChange={e => this.handleChangeInput(e, "data", "email")}
              />
            </div>
            <div className="profile-row">
              <span className="label">Region</span>
              <input
                type="text"
                className="value"
                value={region_name}
                readOnly
              />
            </div>
            <div className="profile-row">
              <span className="label">Cabang</span>
              <input
                type="text"
                className="value"
                value={branch_name}
                readOnly
              />
            </div>
            <div className="profile-row">
              <span className="label">Outlet</span>
              <input
                type="text"
                className="value"
                value={outlet_name}
                readOnly
              />
            </div>
            <div className="profile-row">
              <span className="label">Posisi</span>
              <input
                type="text"
                className="value"
                value={position_name}
                readOnly
              />
            </div>
            <div className="profile-row">
              <span className="label">Update Password</span>
              <input
                type="password"
                className="value"
                value={password}
                onChange={e => this.handleChangeInput(e, "form", "password")}
              />
            </div>
            <div className="profile-row">
              <span className="label">Konfirmasi Password</span>
              <input
                type="password"
                className="value"
                value={confirm_password}
                onChange={e =>
                  this.handleChangeInput(e, "form", "confirm_password")
                }
              />
            </div>
          </div>
          <button className="btn-edit" type="submit">
            Update
          </button>
        </form>
        <div
          style={{ width: "100%", textAlign: "center", margin: "40px 0 60px" }}>
          <button
            className=""
            type="button"
            onClick={this.handleLogout}
            style={{ backgroundColor: "transparent", color: "#ca0813" }}>
            Log Out
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(Profile);

import React, { Component } from 'react'
import { MENU, ASSETS } from '../../config';

export default class ResponseSuccess extends Component {

  render() {

    const handleBackToHome = (e) => {
      e.preventDefault()
      this.props.history.push(MENU.HOME)
    }

    return (
      <div className="popup-notif">
      <div className="content">
        <img src={ ASSETS.ICONS.success } alt="" />
        <h3>Congratulations!</h3>
        <p>
          Terima kasih telah mengerjakan test online. <br />
          Semoga mendapatkan hasil yang terbaik.
        </p>
      </div>
      <a className="btn-back" href="" onClick={ e => handleBackToHome(e) }>Back to Home</a>
    </div>
    )
  }
}

import React from 'react';
import { withRouter } from 'react-router-dom'
import { Toast } from 'antd-mobile';
import { MENU, ASSETS, SITE_COOKIES } from '../../config';
import { serialize } from '../../utils/formatter/url'
import { QuizApi } from '../../api';
import { getCookie } from '../../utils/cookies';


class Home extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      data: {},
      list: [],
      params: {
        limit: 1,
        total: 0,
        page: 1,
        last_page: 1,
      },
      term: {},
      isLoading: true,
      visiblePopup: false,
    };
  }

  componentDidMount() {
    this.init();
  }
  
  init = () => {
    Toast.hide()
    let profile = {
      image: getCookie(SITE_COOKIES.AVATAR)
    }
    this.fetchQuizList()
    this.getTermAndCondition()
    this.setState({ profile })
  }

  fetchQuizList = () => {
    
    Toast.loading('Loading...', 1000, null, true)

    let params = {
      limit: this.state.params.limit,
      page: this.state.params.page
    }

    QuizApi.list(params)
    .then(response => {
      if(response.data.status == 200){
        let { data, list } = this.state
        list = response.data.data
        data = list[1]
        this.setState({ data, list })
      }
      Toast.hide()
    }).catch(err => {
      Toast.hide()
      Toast.fail(err.response.data.message, 1.5, null, false)
    })

  }

  getTermAndCondition = () => {
    let { term } = this.state
    QuizApi.getTermAndCondition()
    .then(response => {
      if(response.data.status == 200){
        term = response.data.data
        this.setState({ term })
      }
      Toast.hide()
    }).catch(err => {
      Toast.hide()
      Toast.fail(err.response.data.message, 1.5, null, false)
    })
  }

  render() {

    let { profile, list, term, visiblePopup } = this.state
    
    const hanldeTogglePopup = (value, data) => {
      this.setState({ visiblePopup: value, data })
    }
    const handleGoToQuiz = (e) => {
      e.preventDefault()
      let { data } = this.state
      localStorage.setItem('QUIZZ', JSON.stringify({
        id: data.id,
        active: true
      }));
      this.props.history.push(MENU.QUIZ)
      // this.props.history.push(`${MENU.QUIZ}/${data.id}/1`)
    }

    const handleClickSetting = e => {
      this.props.history.push(`${MENU.PROFILE}`);
    }


    return (
      <React.Fragment>
        <section className="section-home">
          <div className="header" style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 30 }}>
            <div  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
              <div className="title">
                Welcome at Learning Service Center
              </div>
              <div className="profile" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  className="photo"
                  style={{ 
                    backgroundColor: '#EFEFEF',
                    backgroundImage: `url('${ profile.image }')`,
                    marginLeft: 15
                  }}
                ></div>
                <button className="btn-menu" onClick={ handleClickSetting } style={{ paddingRight: 0, marginLeft: 5 }}>
                  <img src={ ASSETS.ICONS.menu } />
                </button>
              </div>
            </div>
            <div style={{ marginTop: 30, marginBottom: 0, textAlign: 'center', color: 'white' }}>
              <small style={{ fontSize: 14 }}>The place where you want to learn banking service</small>
            </div>
          </div>
          { 
            list.map((item, key) => 
              <div className="body" key={ key }>
                <div className="icon">
                  <img src={ ASSETS.ICONS.book } />
                </div>
                <div className="title">
                  { item.title }
                </div>
                <div className="info">
                  <div><img src={ ASSETS.ICONS.tag } /> { `${item.total_soal} Pertanyaan` }</div>
                  <div><img src={ ASSETS.ICONS.clock } /> { `${item.days_left} Hari tersisa` }</div>
                </div>
                <button className="btn-next" onClick={ e => hanldeTogglePopup(true, item) }>
                  Ikuti Test Sekarang
                </button>
              </div>
            )
          }
        </section>

        <div className={ visiblePopup ? "popup active" : "popup" } >
          <div className="overlay"></div>
          <div className="content">
            <button className="btn-close" onClick={ e => hanldeTogglePopup(false, null) }>
              <img src={ ASSETS.ICONS.close } />
            </button>
            <div className="title">{ term.title }</div><br />
            <div className="scroll" dangerouslySetInnerHTML={{ __html: term.content }} />
            <a href="" onClick={ e => handleGoToQuiz(e) } className="btn-next">
              Setuju dan Lanjutkan Test
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
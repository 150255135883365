import React from 'react';
import { withRouter } from 'react-router-dom'
import { Toast } from 'antd-mobile';
import { MENU } from '../../config';
import { QuizApi } from '../../api';

// window.onbeforeunload = function() { 
//   return "Your work will be lost.";
// };

const intervalTime = 1000

class Quiz extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      data: {
        employee_quiz_id: 0,
        question: {
          employee_question_id: '',
          question_text: ''
        },
        option: [],
        number: 0,
        total: 0,
        duration: 5,
        finish: false,
        isPristine: true,
      },
      hasSelected: false,
      params: {
        limit: 10,
        total: 0,
        page: 1,
        last_page: 1,
      },
      timer: {},
      intervalCountDown: null,
      isLoading: false,
      initLoading: true,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.init();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalCountDown)
  }

  init = async () => {
    let { data } = this.state
    data = {
      employee_quiz_id: 0,
      question: {
        employee_question_id: '',
        question_text: ''
      },
      option: [],
      number: 0,
      total: 0,
      duration: 5,
      finish: false,
      isPristine: true,
    }
    this.setState({ data })
    await this.setState({ isLoading: true })
    Toast.loading('Loading...', 1000, null, true)
    const quizz = localStorage.getItem('QUIZZ')
    if (quizz == null) {
      await this.setState({ isLoading: false })
      Toast.hide()
      setTimeout(() => {
        this.props.history.push(MENU.HOME)
      }, 500)
    } else {
      let data = JSON.parse(quizz)
      this.fetchQuiz(data.id);
    }
  }

  countDown = () => {
    let { timer } = this.state
    let { status, _min, min, _sec, sec } = timer
    if (status == 'start') {
      if (_min == 0 && _sec == 0) {
        status = 'end'
        this.handleSubmit()
      }
      if (_min > 0) {
        if (_sec == 0) {
          _min -= 1
          _sec = 59
        } else {
          _sec--
        }
      } else {
        if (_sec > 0) _sec--
      }
    }
    min = _min < 10 ? '0' + _min : _min
    sec = _sec < 10 ? '0' + _sec : _sec
    timer = {
      _min, _sec,
      min, sec,
      status
    }
    this.setState({ timer })
  }

  fetchQuiz = quizId => {

    let { timer } = this.state
    let params = {
      id: quizId
    }

    QuizApi.get(params)
      .then(async response => {
        if (response.data.status == 200) {
          let { data } = response.data
          timer = {
            status: 'start',
            min: data.duration_per_question < 10 ? '0' + data.duration_per_question : data.duration_per_question,
            _min: data.duration_per_question,
            sec: '00',
            _sec: 0,
          }
          await this.setState({
            data, timer,
            isLoading: false,
            initLoading: false,
            hasSelected: false,
            intervalCountDown: setInterval(() => this.countDown(), intervalTime)
          })
        }
        Toast.hide()
      }).catch(err => {
        Toast.hide()
        let message = "Terjadi kesalahan mengambil data soal"
        if (err.response.status == 500) {
          message = err.response.statusText
        } else {
          message = err.response.data.message
        }
        Toast.fail(message, 1.5, null, true)
      })

  }

  // handler

  handleTimeOut = () => {
    this.handleSubmit()
    // const { quizId, data } = this.state
    // this.props.history.push(`${MENU.QUIZ}/${quizId}/${data.number + 1}`)
  }

  handlePickAnswer = (item) => {
    let { data } = this.state
    data.option = data.option.map(opt => {
      if (opt.option == item.option)
        opt.selected = !opt.selected
      return opt
    })
    data.isPristine = false
    this.setState({ data, hasSelected: true })
  }

  handleSubmit = () => {

    const { data, intervalCountDown } = this.state
    let answers = data.option.filter(item => item.selected == true).map(item => { return item.option })

    if (data.isPristine == false && answers.length == 0) {
      Toast.info('Mohon pilih jabawan terlebih dahulu', 1, null, false)
      return false
    }

    Toast.loading('Loading...', 1000, null, true)

    let payload = {
      employee_quiz_id: data.employee_quiz_id,
      employee_question_id: data.question.employee_question_id,
      number: data.number,
      answer: answers
    }

    clearInterval(intervalCountDown)

    QuizApi.submit(payload)
      .then(async response => {
        if (response.data.status == 200) {
          let { data, timer } = response.data
          data.value = null
          timer = {
            status: 'start',
            _min: data.duration_per_question,
            min: data.duration_per_question < 9 ? '0' + data.duration_per_question : data.duration_per_question,
            _sec: 0,
            sec: '00'
          }
          if (!data.finish) {
            await this.setState({
              data, timer,
              isLoading: false,
              hasSelected: false,
              intervalCountDown: setInterval(() => this.countDown(), intervalTime)
            })
            Toast.hide()
          } else {
            await this.setState({ isLoading: false })
            await localStorage.clear()
            Toast.hide()
            this.props.history.push(`${MENU.RESPONSE_SUCCESS}`)
          }
        }
      }).catch(err => {
        let message = 'Terjadi kesalahan, mohon cobe beberapa saat lagi'
        Toast.hide()
        if (err.response.status == 500) {
          message = err.response.statusText
        } else {
          message = err.response.data.message
        }
        Toast.fail(message, 1.5, null, false)
        this.setState({ isLoading: false })
      })

  }
  // end of handler

  render() {

    let { data, hasSelected, timer, initLoading } = this.state

    const title = {
      whiteSpace: 'pre-line'
    };

    return (
      <React.Fragment>
        {
          !initLoading &&
          <section className="section-quiz" style={{ marginBottom: data != '' ? 60 : 0 }}>
            <div className="header" style={{ display: 'flex' }}>
              <div className="labels" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="item">{data.number} / {data.total} Pertanyaan</div>
                <div className="item timer">
                  {timer.min}:{timer.sec}
                </div>
              </div>
            </div>
            <div className="body">
              {
                data.hasOwnProperty('media')
                  ? data.media.hasOwnProperty('url')
                    ? <div className="banner">
                      <img src={data.media.url} alt={data.media.original_name} />
                    </div>
                    : <React.Fragment />
                  : <React.Fragment />
              }
              {/* <div className="banner">
                <img src="http://via.placeholder.com/460x320" alt="" />
              </div> */}
              <div className="title" style={title}>
                {data.hasOwnProperty('question') && data.question.question_text}
              </div>
              <div className="answer">
                {
                  data.hasOwnProperty('option') && data.option.map((item, key) => {
                    return <span className={item.selected ? "btn-next selected" : "btn-next"} key={key} onClick={e => this.handlePickAnswer(item)}>{item.value}</span>
                  })
                }
              </div>
            </div>
            {hasSelected &&
              <button className="btn-submit fixed" type="button" onClick={this.handleSubmit}>
                SUBMIT
              </button>
            }
          </section>
        }
      </React.Fragment>
    );
  }

}

export default withRouter(Quiz);
import axios from "axios";

export default ({ url, config, configCustom }) => {
  const endpoint = "/user";
  return {
    detail: () => axios.get(`${url}${endpoint}/detail`, config()),
    profile: () => axios.get(`${url}${endpoint}/profile`, config()),
    updateProfile: formData =>
      axios.post(
        `${url}${endpoint}/profile/update`,
        formData,
        config({ contentType: "multipart/form-data" })
      ),
    updateProfileImage: formData =>
      axios.post(
        `https://master-api.squ-bss.com/v1/users/update`,
        formData,
        configCustom({ contentType: "multipart/form-data" })
      ),
  };
};

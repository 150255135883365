import React from 'react';
import { withRouter } from 'react-router-dom'
import { Toast } from 'antd-mobile';
import { MENU } from '../../config';
import { AuthApi } from '../../api';

class ForgotPassword extends React.Component {
  state = {
    form: {
      email: '',
    },
    hasResponse: false,
    loading: false
  }
  onChange = (e, fname) => {
    let { form } = this.state
    form[fname] = e.target.value
    this.setState({ form })
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    Toast.loading('Loading...', 1000, null, true)
    let { form } = this.state
    AuthApi.forgot(form)
    .then(response => {
      console.log(response)
      if(response.data.status == 200){
        Toast.hide()
        this.setState({ hasResponse: true })
      }
    }).catch(err => {
      Toast.hide()
    })
  }
  handleGoTo = (e) => {
    e.preventDefault()
    this.props.history.push(MENU.LOGIN)
  }

  render() {
    let { form, hasResponse, loading } = this.state
    return (
      <section className="section-login section-login--forgot_password">
        <form onSubmit={ this.handleSubmit }>
          <div className="container">
            <div className="title">Forgot Password?</div>
            <p className="subtitle">
              Enter the email you used when you joined and we’ll send you a link to
              reset your password
            </p>
            <div className="form">
              <div className="input-container">
                <input type="email" className="bni-field" placeholder="Email Address" onChange={ e => this.onChange(e, 'email') } value={ form.email } />
              </div>
            </div>
            { 
              hasResponse &&
              <p className="response">
                The link for reset your password has been sent to your email. Please
                check your inbox.
              </p>
            }
            <br />
            <a href="" className="response" onClick={ e => { e.preventDefault(); this.props.history.push(MENU.LOGIN); } }>Kembali ke halaman login</a>
          </div>
          <button htmlType="submit" className={ form.email != '' ? 'btn-submit fixed' : 'btn-submit fixed disabled' } disabled={ form.email == '' }>Send Password</button>
        </form>
      </section>
    );
  }
}

export default withRouter(ForgotPassword);
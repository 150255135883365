import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PrivateRouter from './private-router'
import { SITE_URL, MENU } from '../config'

import Login from '../pages/login';
import Home from '../pages/home';
import ForgotPassword from '../pages/forgot-password';
import Quiz from '../pages/quiz';
import ResponseSuccess from '../pages/response/success';
import Profile from '../pages/profile';

const AppRoute = (
  <Router>
    <div>
      <Switch>
        <Route exact path={ MENU.LOGIN } component={ Login } />
        <Route exact path={ MENU.FORGOT_PASSWORD } component={ ForgotPassword } />
        <PrivateRouter exact path={ MENU.APP } component={ Home } />
        <PrivateRouter exact path={ MENU.HOME } component={ Home } />
        <PrivateRouter exact path={ MENU.PROFILE } component={ Profile } />
        <PrivateRouter exact path={ MENU.QUIZ } component={ Quiz } />
        {/* <PrivateRouter exact path={ `${MENU.QUIZ}/:quizId/:step` } component={ Quiz } /> */}
        <PrivateRouter exact path={ `${MENU.RESPONSE_SUCCESS}` } component={ ResponseSuccess } />
        <Route exact path={ SITE_URL } component={ Login } />
        {/* <Route component={ Page404 } />         */}
      </Switch>
    </div>
  </Router>
)

export default AppRoute